<template>
  <base-material-card
    color="primary"
    icon="mdi-clipboard-text-multiple"
  >
    <template
      v-slot:after-heading
    >
      <div class="display-2 font-weight-light my-2">
        {{ title }}
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="addEnabled"
              absolute
              dark
              fab
              top
              right
              small
              color="indigo"
              v-on="on"
              @click="onAddNewListing"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </div>
    </template>

    <v-text-field
      v-model="listing_search"
      append-icon="mdi-magnify"
      class="ml-auto"
      :label="$t('dataTableSearch')"
      hide-details
      single-line
      style="max-width: 250px;"
    />

    <v-divider class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="listingList"
      item-key="listingId"
      :search.sync="listing_search"
      :sort-by="[]"
      :sort-desc="[]"
      multi-sort
    >
      <template v-slot:item.rContent="{ item }">
        <div
          v-if="Number(item.rContent) != 0"
        >
          {{ Number(item.rContent).toFixed(2) }}
        </div>
        <div
          v-else
        >
          NA
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          style="margin-right: 8px !important;"
          small
          depressed
          color="success"
          @click="onListingEdit(item)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          outlined
          small
          color="error"
          @click="onListingDelete(item)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
  import productAPI from '@/services/productServices'

  export default {
    Name: 'productListings',
    props: {
      product: {
        type: String,
        default: '',
      },
      partner: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      addEnabled: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        listingList: [],
        headers: [
          {
            text: this.$t('colListingProductNo'),
            value: 'productNumber',
            width: '12%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colListingAlias'),
            value: 'productAlias',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colListingProduct'),
            value: 'productName',
            width: '20%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colProductType'),
            value: 'productTypeText',
            width: '12%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colRContent'),
            align: 'end',
            value: 'rContent',
            width: '10%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colProductUnit'),
            value: 'unitofMeasureText',
            width: '8%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colProductStatus'),
            value: 'statusCodeText',
            width: '8%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colActions'),
            value: 'actions',
            width: '10%',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
        ],
        listing_search: '',
      }
    },
    created: function () {
      if (this.product === '' && this.partner === '') {
        productAPI.getAllProductListings()
          .then(response => {
            this.listingList = response.data
          })
          .catch(error => {
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      } else if (this.product !== '' && this.partner === '') {
        productAPI.getProductListingsByProduct(this.product)
          .then(response => {
            this.listingList = response.data
          })
          .catch(error => {
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      } else if (this.product === '' && this.partner !== '') {
        productAPI.getProductListingsByPartner(this.partner)
          .then(response => {
            this.listingList = response.data
          })
          .catch(error => {
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      }
    },
    methods: {
      onAddNewListing () {
        this.$router.push('/settings/listingDetails/0')
      },
      onListingEdit (item) {
        this.$router.push(`/settings/listingDetails/${item.listingId}`)
      },
      onListingDelete (item) {
        const msg = `${this.$t('confirmListingDelete')} ${item.productAlias}\n${this.$t('confirmContinue')}`
        if (confirm(msg)) {
          this.modelProcessing = true
          productAPI.deleteProductListing(item.listingId)
            .then(repsonse => {
              alert(this.$$t('alertListingDelete'))
              this.modelProcessing = false
            })
            .catch(error => {
              this.modelProcessing = false
              this.errorMessage = error.errorMessage
              this.showErrorDialog = true
            })
        }
      },
    },
  }
</script>
